/* eslint-disable */
let $ = window.$;
let URL = window.location.host.includes('http://') ? window.location.host.replace('https://', 'https://') : 'https://' + window.location.host
export const path = URL; //******* Current url of thr hosted app */
//export const playstore_url = "https://play.google.com/store/apps/details?id=in.chartr.transit&hl=en_IN&gl=US"; //*********  playstore usrl of the app */
//export const API_URL="http://oparchi.desicoding.com/api/";
export const API_URL = "https://oparchee.com/server/api/";
export const FILE_URL = "https://oparchee.com/server/files/";


export const FIREBASE_API_URL = "https://fcm.googleapis.com/fcm/send";
export const time_out = 5000;
export const login_r = 5000;
export const refresh_time = 5000;
export const modal_time= 4500;
export const _isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/) ||
    // 192.168.0.141/8 are considered localhost for IPv4.
    window.location.hostname.match(/^192(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);


/**
 * function for the loader
 */

export const _showOverlay = () => {
    // Adds the fullscreen overlay
    var oDiv = $("<div></div>");
    oDiv.attr("id", "lt_overlay");
    oDiv.css("display", "block");
    $("body").append(oDiv);

    // Adds the spinner
    //var lDiv = $('<i class="fa fa-cog fa-spin"></i>');
    var lDiv = $('<img src="images/loader.gif" />');
    lDiv.attr("id", "lt_loading");
    //lDiv.addClass("fa fa-cog fa-spin fa-5x");
    lDiv.css("display", "block");
    $("body").append(lDiv);
}

/**
* 
*/
export const _hideOverlay = () => {
    $("#lt_loading").remove();
    $("#lt_overlay").remove();
}


export const _clearInterval = (t) => {
    clearInterval(t);
}
export const _clearAllInterval = () => {
    const intervalId = window.setInterval(() => { }, Number.MAX_SAFE_INTEGER);
    for (let i = 1; i < intervalId; i++) {
        window.clearInterval(i);
    }
}


// export const _showOverlay = () => {
//   // Adds the fullscreen overlay
//   var oDiv = $("<div></div>");
//   oDiv.attr("id", "lt_overlay");
//   oDiv.css("display", "block");
//   $("body").append(oDiv);

//   // Adds the spinner
//   //var lDiv = $('<i class="fa fa-cog fa-spin"></i>');
//   var lDiv=$('<img src="/images/loader.gif" />');
//   lDiv.attr("id", "lt_loading");
//   //lDiv.addClass("fa fa-cog fa-spin fa-5x");
//   lDiv.css("display", "block");
//   $("body").append(lDiv);
// }

/**
 * function to hide loader
 */
// export const _hideOverlay = () => {
//   $("#lt_loading").remove();
//   $("#lt_overlay").remove();
// }


export const routes = () => {
    let data = '';
    if (window.sessionStorage) {
        let routes = JSON.parse(window.sessionStorage.getItem('routes'));
        if (typeof routes != "undefined" && routes != null) {
            data = routes;
        }
    }
    return data;
}
/**
 * function to show the tooltip.
 */
export const _showConInfo = () => {
    // $('.con-info').tooltip({ title: "Internet Connectivity Required", trigger: "manual" });
    // $('.con-info').tooltip('toggle');
    // // $('.con-info').tooltip({title: "Internet Connectivity Required", trigger: "click"});
    // setTimeout(() => {
    //   $('.con-info').tooltip('hide');
    // }, 2000);
    // setTimeout(() => {
    //   //$('.con-info').trigger('click');
    //    $('.bs-tooltip-top').remove();
    // }, 2000);

}

/**
 * 
 */
export const _validatePassword = (data) => {

    let password = data.get('password');
    let confirm_password = data.get('confirm_pass');
    let response = [];
    response['status'] = false;
    response['error'] = [];

    if (typeof confirm_password != "undefined" && confirm_password != null && confirm_password.trim() != '' && typeof password != "undefined" && password != undefined && password.trim() != '') {

        if (password == confirm_password) {
            if (password.length >= 10 && password.length <= 16) {
                let regex = /^.*(?=.{10,16})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&!-_]).*$/;
                if (!regex.test(password)) {
                    response['error']['password'] = "Password should have atleast 1 lower case, 1 upper case, 1 numeric and 1 special character."
                    response['error']['confirm_pass'] = "Password should have atleast 1 lower case, 1 upper case, 1 numeric and 1 special character.";
                } else {
                    var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;

                    if (format.test(password)) {
                        response['status'] = true;
                    } else {
                        response['error']['password'] = "Password should have atleast 1 lower case, 1 upper case, 1 numeric and 1 special character."
                        response['error']['confirm_pass'] = "Password should have atleast 1 lower case, 1 upper case, 1 numeric and 1 special character.";

                    }

                }
            } else {
                response['error']['password'] = "Password length should be between 10 to 16."
                response['error']['confirm_pass'] = "Password length should be between 10 to 16.";
            }


        } else {

            response['error']['confirm_pass'] = "Confirm password should be same as Password";
        }

    } else {
        response['error']['password'] = _checkNull(password);
        response['error']['confirm_pass'] = _checkNull(confirm_password);
    }
    return response;
}

/**
* 
*/
export const _validateLogin = (data) => {

    let phone_number = data.get('phone_number');
    let password = data.get('password');
    let response = [];
    response['status'] = false;
    response['error'] = [];
    response['error']['error_phone_number'] = '';
    response['error']['error_pass'] = '';

    if (typeof phone_number != "undefined" && phone_number != null && phone_number.trim() != '' && typeof password != "undefined" && password != undefined && password.trim() != '') {
        if (_validateNumber(phone_number)) {
            response['status'] = true;
        } else {
            response['error']['error_phone_number'] = "Please provide a valid Phone number.";

        }
    } else if (typeof phone_number != "undefined" && phone_number != null && phone_number.trim() != '' && (typeof password == "undefined" || password == undefined || password.trim() == '')) {

        response['error']['error_pass'] = "Password is required";
        if (_validateNumber(phone_number)) {

        } else {
            response['error']['error_phone_number'] = "Please provide a valid Phone number.";

        }
    } else {
        if (typeof phone_number == "undefined" || phone_number == null || phone_number.trim() == '') {
            response['error']['error_phone_number'] = "Phone number is required";
        }
        if (typeof password == "undefined" || password == undefined || password.trim() == '') {
            response['error']['error_pass'] = "Password is required";
        }

    }
    return response;
}

/**
* 
*/
export const _validateRegistration = async (data) => {

    //let email = data.get('email');
    let full_name = data.get('full_name');
    let phone_number = data.get('phone_number');
    let password = data.get('password');
    let confirm_password = data.get('confirm_password');

    //let user_name = data.get('first_name');
    let response = [];
    response['status'] = false;
    response['error'] = [];

    if (typeof full_name != "undefined" && full_name != undefined && full_name.trim() != '' &&
        typeof password != "undefined" && password != undefined && password.trim() != '' &&
        typeof confirm_password != "undefined" && confirm_password != undefined && confirm_password.trim() != '' &&
        typeof phone_number != "undefined" && phone_number != null && phone_number.trim() != '') {
        if (password === confirm_password) {
            //let res = await _checkFields('email', email);
            if (!_validateNumber(phone_number)) {
                response['error']['phone_number'] = "Please provide a valid phone number.";
            } else {
                if (phone_number.toString().length == 10) {
                    response['status'] = true;
                } else {
                    response['error']['phone_number'] = "Please provide a valid phone number.";
                }

            }
        } else {
            response['error']['confirm_password'] = "Password should be same as confirm password.";

        }
    } else {
        //response['error']['email'] = _checkNull(email);

        // if (response['error']['email'].trim() == '' || response['error']['email'] == null) {
        //     if (_validateEmail(email)) {
        //         let res = await _checkFields('email', email);
        //         if (res.data.response == 'success') {
        //             response['error']['email'] = res.data.error;;
        //         } else {
        //             // response['status'] = true;
        //         }
        //     } else {
        //         response['error']['email'] = "Please provide a valid Email Address";
        //     }
        // }
        response['error']['full_name'] = _checkNull(full_name);
        response['error']['phone_number'] = _checkNull(phone_number);
        response['error']['password'] = _checkNull(password);
        response['error']['confirm_password'] = _checkNull(confirm_password);
        //response['error']['phone_number'] = _checkNull(phone_number);

        if (password === confirm_password) {
        } else {
            response['error']['confirm_password'] = "Password should be same as confirm password.";

        }
        if (response['error']['phone_number'].trim() == '' || response['error']['phone_number'] == null) {

            if (_validateNumber(phone_number)) {
                if (phone_number.toString().length == 10) {
                    //response['status'] = true;
                } else {
                    response['error']['phone_number'] = "Please provide a valid phone number.";
                }
            } else {
                response['error']['phone_number'] = "Please provide a valid phone number";

            }
        }
        //response['error']['country_code'] = _checkNull(country_code);
    }

    return response;
}

export const _validateFormTest = async (data, type) => {
    let response = [];
    response['status'] = false;
    response['error'] = [];
    if (type == 'Month') {
        let fitting_sbk = data.get('fitting_sbk');
        let sbk_codes = data.get('block_sbk');
        let inspection_date = data.get('inspection_date');
        let result = data.get('result');
        let fault_choice_id = data.getAll('fault_choice_id');
        console.log(fault_choice_id);
        if (typeof fitting_sbk != "undefined" && fitting_sbk != null && fitting_sbk.trim() != '' && typeof sbk_codes != "undefined" && sbk_codes != undefined && sbk_codes.trim() != ''
            && typeof inspection_date != "undefined" && inspection_date != null && inspection_date.trim() != '' && typeof result != "undefined" && result != undefined && result.trim() != ''
        ) {
            if (result == 'FAILED') {
                if (typeof fault_choice_id == "undefined" || fault_choice_id == null) {
                    response['error']['fault_choice_id'] = _checkNull(fault_choice_id);
                } else {
                    response['error']['fault_choice_id'] = _checkNull(fault_choice_id);
                    if (response['error']['fault_choice_id'].trim() != '') {
                        //response['error']['fault_choice_id']=
                    } else {
                        response['status'] = true;
                    }

                }
            } else {
                response['status'] = true;
            }


        } else {

            response['error']['fitting_sbk'] = _checkNull(fitting_sbk);
            response['error']['block_sbk'] = _checkNull(sbk_codes);
            response['error']['inspection_date'] = _checkNull(inspection_date);
            response['error']['result'] = _checkNull(result);
            //console.log(response['error']['result']);
            if (response['error']['result'].trim() != '') {
                response['error']['fault_choice_id'] = _checkNull(fault_choice_id);
            } else {
                if (result == 'FAILED') {
                    response['error']['fault_choice_id'] = _checkNull(fault_choice_id);
                }

            }




        }

        return response;
    }
}

export const _validateRepairForm = async (data) => {
    let response = [];
    response['status'] = false;
    response['error'] = [];

    let fitting_sbk = data.get('fitting_sbk');
    let sbk_codes = data.get('block_sbk');
    let inspection_date = data.get('inspection_date');
    //let result = data.get('result');
    let result = 'PASSED';
    let fault_choice_id = data.getAll('fault_choice_id');
    let month = data.get('month');
    if (typeof fitting_sbk != "undefined" && fitting_sbk != null && fitting_sbk.trim() != '' && typeof sbk_codes != "undefined" && sbk_codes != undefined && sbk_codes.trim() != ''
        && typeof inspection_date != "undefined" && inspection_date != null && inspection_date.trim() != '' && typeof result != "undefined" && result != undefined && result.trim() != ''
        && typeof month != "undefined" && month != null && month.trim() != '') {
        if (result == 'FAILED') {
            if (typeof fault_choice_id == "undefined" || fault_choice_id == null) {
                response['error']['fault_choice_id'] = _checkNull(fault_choice_id);
            } else {
                response['error']['fault_choice_id'] = _checkNull(fault_choice_id);
                if (response['error']['fault_choice_id'].trim() != '') {
                    //response['error']['fault_choice_id']
                } else {
                    response['status'] = true;
                }

            }
        } else {
            response['status'] = true;
        }


    } else {

        response['error']['fitting_sbk'] = _checkNull(fitting_sbk);
        response['error']['block_sbk'] = _checkNull(sbk_codes);
        response['error']['inspection_date'] = _checkNull(inspection_date);
        response['error']['result'] = _checkNull(result);
        response['error']['month'] = _checkNull(month);
        //console.log(response['error']['result']);
        if (response['error']['result'].trim() != '') {
            response['error']['fault_choice_id'] = _checkNull(fault_choice_id);
        } else {
            if (result == 'FAILED') {
                response['error']['fault_choice_id'] = _checkNull(fault_choice_id);
            }

        }




    }
    //console.log(response);
    return response;

}
/**
* 
* @param {*} data 
*/
export const _validateNumber = (data) => {
    let response = false;
    var pattern = /[^0-9]/g;
    var result = data.match(pattern);

    if (result == null) {
        response = true;
    }
    return response;
}

/**
* 
* @param {*} field 
*/
export const _checkNull = (field) => {
    let error = '';

    if (typeof field == "undefined" || field == null || (!Array.isArray(field) && field.trim() == '')) {
        error = "This field is required";
    } else {
        if (field.length == 0) {
            error = "This field is required";
        }
        if (field.length == 1 && field[0].trim() == '') {
            error = "This field is required";
        }
    }
    return error;
}

/**
 * 
 * @param {*} field 
 */
export const _checkImageNull =(field)=>{
    let error = '';

    if (field.name.trim() == '' || field.size == 0) {
        error = "This field is required";
    } else {
        error = '';
    }
    return error;
}

export const _checkSpecialChar =(field) =>{
    let error = '';
    var regex =/^[a-zA-Z0-9 ]*$/;
     if(field.match(regex)){
     }else{
        error = "No special characters are allowed.";
    }
     return error;
}

/**
* 
* @param {*} email 
*/
export const _validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

/**
* 
* @param {*} email 
* @param {*} password 
*/
export const _rememberMe = (email, password) => {

    if (window.localStorage) {
        let legacy_key = [];
        legacy_key.push({ 'email': email, 'password': btoa(password), 'isChecked': 'on' });
        window.localStorage.setItem('legacy_key', JSON.stringify(legacy_key));
    }
    return;
}


/**
* 
* @param {*} email 
* @param {*} password 
*/
export const _donotRememberMe = () => {

    if (window.localStorage) {
        window.localStorage.removeItem('legacy_key');
    }
    return;
}

/**
* 
*/
export const _browserLogin = (type) => {
    let data = '';
    if (window.localStorage) {
        let user = JSON.parse(window.localStorage.getItem('user'));
        //let token = JSON.parse(window.localStorage.getItem('user_token'));

        if (typeof user != "undefined" && user != null) {

            if (user.user_type == 'Patient' && type == "Patient") {
                data = {
                    pathname: '/patient-selection',
                    state: { user: user }
                };

            }
            if (user.user_type == 'Doctor' && type == "Doctor") {
                data = {
                    pathname: '/doctors-list',
                    state: { user: user }
                }

            }

        }
    }
    return data;
}

export const _getUserDoctor = () => {
    let response = [];
    if (window.localStorage) {
        let user = JSON.parse(window.localStorage.getItem('user_doctor'));
        if (typeof user != "undefined" && user != null) {
            response['data'] = user;
            response['status'] = true
        }
    }
    return response;
}
export const _getToken = () => {
    let response = [];
    response['status'] = false;
    if (window.localStorage) {
        let token = JSON.parse(window.localStorage.getItem('user_token'));
        //console.log(token);
        if (typeof token != "undefined" && token != null) {
            response['token'] = token;
            response['status'] = true
        }
    }
    //console.log(response);
    return response;
}

/**
* 
*/
export const _getLoginStatus = () => {
    let response = [];
    response['status'] = false;
    
    let user=_getCookie('user');
    //console.log(user);
    if (typeof (user) != 'undefined' && user != null && user != "") {
        response['data'] = JSON.parse(user);
        response['status'] = true
    } else {
        response['status'] = false;
       
       // console.log('cookie exist');
    }
    // if (window.localStorage) {
    //     let user = JSON.parse(window.localStorage.getItem('user'));
    //     if (typeof user != "undefined" && user != null) {
    //         response['data'] = user;
    //         response['status'] = true
    //     }
    // }
    return response;
}

/**
 * 
 * @param {*} cname 
 * @returns 
 */
const _getCookie = (cname) => {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

const _deleteCookies =()=>{
    var allCookies = document.cookie.split(';');
    // The "expire" attribute of every cookie is 
    // Set to "Thu, 01 Jan 1970 00:00:00 GMT"
    for (var i = 0; i < allCookies.length; i++)
        document.cookie = allCookies[i] + "=;expires="
        + new Date(0).toUTCString();
}

/**
 * 
 */
const  _deleteCookie =(name) =>{
    document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    return;
  }




/**
* 
*/
export const _logOut = async (param) => {
    await localStorage.removeItem("user");
    await localStorage.removeItem("user_doctor");
    await localStorage.removeItem("user_token");
    //await _deleteCookies();
    await _deleteCookie("user");
    //await _deleteUserTokenCookie("user_token");
    let path = "/home";
    _clearAllInterval();
    return path;
}

/**
* 
*/
export const _checkVersion = async () => {
    let get_version = window.localStorage.getItem("version");
    let res = await axios.get(`${API_URL}version.json`, {
        params: {
            version: (typeof get_version != 'undefined' && get_version !== '' && get_version != null) ? get_version : 0,
        }
    });
    if (res.data.response === 'success') {
        let new_version = res.data.result.version;

        if (new_version == get_version) {
            //window.localStorage.setItem("version", new_version)
        } else {
            window.localStorage.setItem("version", new_version)
            setTimeout(() => {
                window.location.reload(true);
            }, 100);
        }
    }

}

/**
 * 
 */
export const _forcedLogout = () => {
    // localStorage.removeItem("user");
    // localStorage.removeItem("user_token");
    $('#warning-modal').modal({
        backdrop: 'static',
        keyboard: false
    }).modal('show');

    setTimeout(async () => {
        let logout = await _logOut();
        // window.location.href = '/asset-pwa/login/';
    }, 5000);
}

/**
 * 
 * @returns 
 */
export const _isInstalled = () => {
    // For iOS
    if (window.navigator.standalone) return true

    // For Android
    if (window.matchMedia('(display-mode: standalone)').matches) return true

    // If neither is true, it's not installed
    return false
}


/**
 * 
 * @returns 
 */
export const _removeLocalDoctor = () => {

    if (window.localStorage) {
        window.localStorage.removeItem('user_doctor');
    }
    return;
}

export const _languageSelection = (lang) => {

    if (window.localStorage) {
        let language = lang;
        document.cookie = "language="+language+"; expires=Tue, 19 Jan 2038 04:14:07 GMT;path=/;";
       // window.localStorage.setItem('language', language);
    }
    return;
}

export const _getLocalLanguage = () => {
    let language = [];
    //language['lang'] = window.localStorage.getItem("language");
    language['lang'] = _getCookie("language");
    if (typeof language['lang'] != 'undefined' && language['lang'] != null && language['lang'] != '') {
        language['lang_select'] = true;
        //alert(language.lang);
    } else {
        language['lang_select'] = false;
        //alert(language.lang);
        //language['lang'] = 'en';
    }
    return language;
}

export const _getLocationCookie =()=>{
      //let user_type = window.localStorage.getItem("user_type");
      let location = _getCookie("location");
      //alert(user_type);
      if (typeof location != 'undefined' && location != null && location != '') {
          // language['lang_select']=true;
      } else {
        location = 'Deoria';
      }
      return location;
}

export const _setLocationCookie=(location)=>{
    if (window.localStorage) {
        let user_location = location;
        document.cookie = "location="+user_location+"; expires=Tue, 19 Jan 2038 04:14:07 GMT;path=/;";
       // window.localStorage.setItem('user_type', user_type);
    }
    return;
}

export const _userType = (type) => {

    if (window.localStorage) {
        let user_type = type;
        document.cookie = "user_type="+user_type+"; expires=Tue, 19 Jan 2038 04:14:07 GMT;path=/;SameSite=Lax";
       // window.localStorage.setItem('user_type', user_type);
    }
    return;
}

export const _getUserType = () => {

    //let user_type = window.localStorage.getItem("user_type");
    let user_type = _getCookie("user_type");
    //alert(user_type);
    if (typeof user_type != 'undefined' && user_type != null && user_type != '') {
        // language['lang_select']=true;
    } else {
        user_type = 'patient';
    }
    return user_type;
}

export const _setCookie = () => {
    document.cookie = "cookieName= true; expires=Tue, 19 Jan 2038 04:14:07 GMT";
}

export const _getLocationUsingNavigator =()=>{
    if ("geolocation" in navigator) {

          navigator.geolocation.getCurrentPosition(function(position) {

            console.log(position)
      
          });
  
      } else {
  
        console.log("Not Available");
  
      }
}

