export const lang_hi = [{
    "new_text":"नया",
    "version_text":"संस्करण",
    "patient_portal_text":"रोगी का पोर्टल",
    "doctor_portal_text":"डॉक्टर का पोर्टल",
    "phone_text":"मोबाइल",
    "mobile_text":"मोबाइल",
    "number_text":"संख्या",
    "email_text":"ईमेल",
    "password_text":"पासवर्ड",
    "forgot_password_text":"पासवर्ड भूल गए?",
    "login_text":"लॉग इन करें",
    "seamless_experience_text":"एक निर्बाध अनुभव करना चाहते हैं?",
    "download_our_app_text":"अभी हमारा ऐप डाउनलोड करें।",
    "download_text":"डाउनलोड",
    "copyright_text":"कॉपीराइट ©",
    "all_right_reserved_text":"सर्वाधिकार सुरक्षित",
    "venture_text":"केआर प्राइवेट लिमिटेड का एक उद्यम.",
    "no_account_text":"खाता नहीं है?",
    "register_text":"पंजीकरण",
    "loading_div_text":"लोड हो रहा है, कृपया प्रतीक्षा करें.....",
    "welcome_text":"स्वागत",
    "search_text":"खोज",
    "home_text":"होम",
    "account_text":"खाता",
    "emergency_text":"आपातकाल",
    "history_text":"इतिहास",
    "contact_us_text":"संपर्क करें",
    "faq_text":"अक्सर पूछे जाने वाले प्रश्न",
    "app_version_text":"एप्लिकेशन वेरीज़न",
    "v_text":"वी",
    "registration_text":"पंजीकरण",
    "full_name_text":"पूरा नाम",
    "number_text":"संख्या",
    "confirm_text":"पुष्टि करें",
    "already_have_account_text":"पहले से ही एक खाता है?",
    "login_text":"लॉग इन करें",
    "here_text":"यहां",
     "dynamic_note_text":"मेडिकल डिलीवरी ऑनलाइन है। हम आपको रोजाना सेहत का डोज पहुंचाएंगे।",
     "extra_text":"",
    "banner_text":"विभिन्न प्रकार के डॉक्टरों के साथ अपॉइंटमेंट बुक करें।",
    "featured_services_text":"विशेष रुप से सेवाएं",
    "book_text":"बुक",
    "parchee_text":"पर्ची",
    "other_text":"अन्य",
    "labs_text":"प्रयोगशाला",
    "medical_text":"चिकित्सा",
    "delivery_text":"वितरण",
    "ambulance_text":"रोगी वाहन",
    "service_text":"सेवा",
    "shoulder_text":"कंधा",
    "pain_text":"दर्द",
    "stomach_text":"पेट",
    "headche_text":"सिरदर्द",
    "back_text":"पीठ",
    "cough_text":"खाँसी",
    "fever_text":"बुखार",
    "acne_text":"मुंहासा",
    "blood_pressure_text":"रक्त चाप",
    "blood_text":"रक्त",
    "diabetes_text":"मधुमेह",
    "consult_text":"परामर्श",
    "now_text":"अभी",
    "community_message_text":"आपके शहर में हमारे पास डॉक्टरों का एक बड़ा समुदाय है।",
    "our_text":"हमारी",
    "mission_text":"मिशन",
    "motto_text":"सिद्धांत",
    "our_mission_motto_text":"हमारा छोटा उद्यम इस बात को ध्यान में रखते हुए बनाया गया था कि एक मरीज को बुनियादी स्वास्थ्य सेवाओं का लाभ उठाने के लिए भी परेशानी का सामना करना पड़ता है, जैसे डॉक्टर या प्रयोगशाला परीक्षण के लिए समय पर नियुक्ति प्राप्त करना या आवश्यक दवाएं प्राप्त करना। हम चाहते हैं कि हर मरीज को अपना कीमती समय और प्रयास बचाने में मदद करके आसानी हो, जो कि अपने स्वयं के स्वास्थ्य को बनाए रखने पर खर्च किया जाना चाहिए।",
    "hospitals_text":"अस्पताल",
    "doctors_text":"चिकित्सक",
    "users_text":"उपयोगकर्ता",
    "an_text":"an",
    "book_an_appointment_text":"एक अपॉइंटमेंट बुक करें",
    "appointment_text":"अपॉइंटमेंट",
    "search_text":"खोज",
    "status_text":"स्थिति",
    "details_text":"विवरण",
    "of_text":"का",
    "patient_text":"रोगी",
    "for_text":"के लिए",
    "whom_text":"किस",
    "address_text":"पता",
    "age_text":"उम्र",
    "problem_text":"समस्या",
    "doctor_text":"चिकित्सक",
    "name_text":"नाम",
    "booking_text":"बुकिंग",
    "date_text":"तारीख",
    "fees_text":"फीस",
    "app_text":"एप्लिकेशन",
    "charges_text":"प्रभार",
    "prescription_text":"पर्चे",
    "image_text":"छवि",
    "upload_text":"डालना",
    "upload_multiple_text":"एक या अधिक फ़ाइलें अपलोड करें",
    "submit_text":"सबमिट",
    "unregistered_text":"अपंजीकृत",
    "book_unregistered_text":"यहां अपंजीकृत डॉक्टरों को बुक करें",
    "no_doctor_found_text":"कोई डॉक्टर नहीं मिला।",
    "booking_text":"बुकिंग",
    "slot_text":"स्लॉट",
    "on_text":"पर",
    "edit_text":"संपादित करें",
    "edit_profile_text":"प्रोफ़ाइल संपादित करें",
    "profile_text":"प्रोफ़ाइल",
    "change_text":"बदलना",
    "logout_text":"लॉग आउट",
    "oparchee_text":"ओ-पर्ची",
    "recover_text":"पुनर्प्राप्त करें",
    "consult_specialist_doctors_text":"सीधे हमारे विशेषज्ञ डॉक्टरों से सलाह लें।",
    "click_on_icon_text":"हमारे विशेषज्ञ डॉक्टरों को ब्राउज़ करने के लिए आइकन पर क्लिक करें।",
    "general_physician_text":"सामान्य चिकित्सक",
    "bone_text":"हड्डी",
    "specialist_text":"विशेषज्ञ",
    "dentist_text":"दाँतों का डॉक्टर",
    "gastro_text":"पेट का",
    "heart_&_neuro_text":"दिल और न्यूरो",
    "female_text":"महिला",
    "new_version_text":"नया संस्करण",
    "new_version_summary_text":"ऐप का नया संस्करण उपलब्ध है। ऐप को अपडेट करने के लिए कृपया अपडेट बटन पर क्लिक करें।",
    "update_text":"अपडेट करें",
    "language_text":"भाषा",
    "choose_language_text":"भाषा चुनें",
    "hindi_text":"हिंदी",
    "english_text":"अंग्रेज़ी",
    "continue_text":"जारी रखें",
    "have_health_issues_text":"स्वास्थ्य संबंधी समस्याएं हैं?",
    "vision_motto_text":"हमारी दृष्टि और आदर्श वाक्य",
    "self_text":"स्वयं",
    "loading_wait_text":"लोड हो रहा है, कृपया प्रतीक्षा करें.....",
    "has_not_started_text":"अभी तक शुरू नहीं हुआ है।",
    "is_consult_text":"अब डॉक्टर से परामर्श कर रहा है।",
    "has_done_consult_text":"डॉक्टर से सलाह कर लिया गया है।",
    "token_num_text":"टोकन नंबर.",
    "info_text":"जानकारी",
    "distance_text":"दूरी",
    "filling_fast_text":"तेजी से भर रहा है",
    "sorry_slots_text":"क्षमा करें स्लॉट भरे हुए हैं",
    "closed_text":"बंद",
    "join_text":"शामिल हों",
    "today_text":"आज",
    "another_text":"कोई अन्य",
    "day_text":"दिन",
    "morning_text":"सुबह",
    "evening_text":"शाम",
    "afternoon_text":"दोपहर",
    "select_a_date_text":"कोई दिनांक चुनें",
    "help_&_support":"सहायता और समर्थन",
    "we_welcome_text":"हम आपका ओ-पर्ची पर स्वागत करते हैं।",
    "logout_message_text":"आपने O-Parchee से सफलतापूर्वक लॉग आउट कर लिया है!",
    "sorry_text":"माफ कीजिए!",
    "wrong_crendentials_text":"गलत फोन नंबर या पासवर्ड प्रदान किया गया।",
    "booking_confirmation_text":"बुकिंग की पुष्टि",
    "booking_added_text":"बुकिंग सफलतापूर्वक जोड़ी गई।",
    "for_any_query_text":"किसी भी प्रश्न के लिए हमसे नीचे संपर्क करें",
    "contact_us_whatsapp_text":"हमसे व्हाट्सएप पर संपर्क करें",
    "previous_order_text":"पिछली खरीदारी",
    "new_orders_text":"खरीद करें",
    "purchase_text":"",
    "order_details_text":"ऑर्डर का विवरण",
    "normal_text":"सामान्य",
    "urgent_text":"तत्काल",
    "delivery_type_text":"वितरण के प्रकार",
    "order_confirmation_text":"चिकित्सा खरीद की पुष्टि",
    "order_added_text":"खरीदारी सफलतापूर्वक जोड़ी गई",
    "delivery_text":"वितरण",
    "booking_error_text":"बुकिंग करते समय एक गड़बड़ी हुई थी।",
    "please_try_again_later_text":"कृपया बाद में पुन: प्रयास करें।",
    "amount_text":"भुगतान",
    "payment_text":"भुगतान",
    "city_text":"शहर",
    "doctor_prescription_image_text":"डॉक्टर के पर्चे की छवि",
    "file_not_found_text":"फ़ाइल प्राप्त नहीं हुई",
    "medical_delivery_history_text":"चिकित्सा वितरण इतिहास",
    "medical_delivery_text":"चिकित्सा वितरण",
    "km_charges_text":"* तत्काल वितरण प्रति कि.मी. शुल्क। वितरण पते और वितरण पिकअप पते के बीच की दूरी।",
    "order_confirmation_call_text":"एक पंजीकृत फार्मासिस्ट आपके आदेश और उसके भुगतान विवरण की पुष्टि करने के लिए आपको कॉल करेगा।",
    "account_settings_text":"अकाउंट सेटिंग",
    "total_bookings_text":"कुल बुकिंग",
    "booking_details_text":"बुकिंग विवरण",
    "pending_booking_text":"लंबित बुकिंग",
    "cancelled_booking_text":"रद्द बुकिंग",
    "accepted_booking_text":"स्वीकृत बुकिंग",
    "agent_offer_text":"पंजीकृत बुकिंग एजेंटों के लिए विशेष बुकिंग प्रस्ताव। प्रत्येक 70 नई स्वीकृत बुकिंग के लिए ₹1000 नकद प्राप्त करें।",
    "about_us_text":"हमारे बारे में",
    "refund_policy_text":"भुगतान वापसी की नीति",
    "privacy_policy_text":"गोपनीयता नीति",
    "terms_and_condition_text":"नियम व शर्तें",
    "test_name_text":"परीक्षण का नाम",
    "lab_text":"प्रयोगशाला",
    "test_text":"परीक्षण",
    "non_trauma_emergency_text": "गैर आघात आपातकाल",
    "trauma_emergency_text": "आघात आपातकाल",
   "emergency_order_confirmation_call_text": "हमारे कार्यकारी आपको आपकी आपातकालीन चिकित्सा बुकिंग और अन्य विवरणों की पुष्टि करने के लिए कॉल करेंगे",
   "deoria_text":"देवरिया",
   "gorakhpur_text":"गोरखपुर",
   "government_text":"सरकारी",
   "department_text":"विभाग",
   "ophthalmology_text":"नेत्र विज्ञान",
   "cardiology_text":"हृदयरोगविज्ञान",
   "orthopaedics_text":"हड्डी",
   "ent_text":"कान, नाक और गला",
   "surgeon_text":"शल्य चिकित्सक",
   "general_physician_text":"सामान्य चिकित्सक",
   "chest_text":"छाती",
   "psychiatrist_text":"मनोचिकित्सक",
   "dermatology_text":"त्वचा विज्ञान",
   "medicine_text":"औषधि",
   "obstetrics_and_gynaecology":"प्रसूति एवं स्त्री रोग",
   "namaste_text":"नमस्ते!",
   "verified_oparchee_doctors":"सत्यापित ओपर्ची डॉक्टरों के लिए नियुक्ति बुक करें।"


   

    


    





}];